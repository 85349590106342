import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'Aptamil',
  siteName: 'hk-am',
  siteSlug: 'hk-am',
  defaultLocale: 'zh-HK',
  localeConfigs: [
    {
      country: 'HK',
      currency: 'HKD',
      locale: 'zh-HK',
      initMessages: () =>
        import('../translations/zh_HK.json').then((r) => r.default),
      urls: {
        faq: '/faq.html',
        privacy: '/privacy.html',
        dataCollection: '/data-collection.html',
        termsAndConditions: '/terms-and-conditions.html',
        contact: '/blog/contact-us.html',
      },
    },
  ],
  providerId: 'danis_eu',
  ctHost: 'https://api.europe-west1.gcp.commercetools.com',
  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',
  ctStoreKey: 'AM',
  analytics: {
    tracker: 'aem',
    language: 'en-HK',
    defaultCategory: 'Baby food',
  },
  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },
  checkout: {
    stepAddress: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/hk-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),
    checkboxes: [
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTermsAndDataCollection,
      },
    ],
    checkboxesAccountCreation: [
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTermsAndDataCollection,
      },
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
    ],
  },
  domain: "HKAPTA",
  clubId: "HKAPTA",
  country: "HK"
}
